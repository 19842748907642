import React, { useState } from 'react'
import { HideIcon, ShowIcon } from '../../assets/images';

const ShowHidePassword = ({ name,placeholder,value }) => {
    const [isVisible, setVisible] = useState(false);
    const toggle = () => {
        setVisible(!isVisible);
      };

  return (
    <div className="inputBox">
                  <input type={!isVisible ? "password" : "text"}  name={name} required
                    className="form-control w-100" placeholder={placeholder}
                  />
                  <span className="" onClick={toggle}>
                  {isVisible ?  
                      <img src={HideIcon} alt="Hide" className="HideImage" />
                      : 
                      <img src={ShowIcon} alt="show" className="ShowImage" />
                    }
                  </span>
                </div>
  )
}

export default ShowHidePassword