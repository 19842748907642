
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import {
  ImagesIcon,
  CreateIcon,
  ContentIcon,
  CreditsIcon,
  CopyIcon,
  DownloadIcon,
  ImagesGrey,
  ContentGrey,
  CreditsInact,
  backArrow,
  paginationloader,
  Failure,
  Verified,
} from "../../assets/images";
import "./MyCreations.scss";
import NavbarMenu from "../../Components/Navbar/Navbar";
import { toast } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../assets/Loader.json";
import Lottie from "lottie-react";
import {
  mycreationswithPagination,
  uploadstatus,
} from "../ApiService/ApiService";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import JSZip from "jszip";
const MyCreations = ({ item }) => {
  const [activeClass, setActiveClass] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [totalCount, settotal_Count] = useState(0);
  const [loadmessage, setLoadmessage] = useState(false);
  const [page, setpage] = useState(1);
  const [paginationloading, setpaginationloading] = useState(false);

  const [MyCreationApiValues, setMyCreationApiValues] = useState([]);
  const [detailedHistory, setdetailedHistory] = useState(
    MyCreationApiValues[0]
  );

  const historyResponseRef = useRef(null);
  const scrollToHistoryResponse =()=>{
  if(historyResponseRef.current){
    historyResponseRef.current.scrollIntoView({behavior:"smooth"})
  }
  }

  const leftDivRef = useRef(null);
  const rightDivRef = useRef(null);

  const [leftScrollPosition , setLeftScrollPosition] = useState(0);

  useEffect(()=>{
    leftDivRef.current.scrollTop =leftScrollPosition; 
  },[leftScrollPosition])
  

  const downloadImage = (getImage) => {
    const link = document.createElement("a");
    link.href = getImage;
    link.download = "image.png";
    link.click();
    link.remove();

    //     headers: {
    //       'Origin': 'https://app.i11labs.com',
    //     },
    //     mode: 'no-cors',
    //   })
    // .then((response) => {
    //   return response.blob();
    // })
    // .then((blob) => {
    //   const url = window.URL.createObjectURL(blob);
    //   const a = document.createElement('a');
    //   a.href = url;
    //   a.download = 'image.png';
    //   a.click();
    //   window.URL.revokeObjectURL(url);
    // });
  };

  const downloadImagesAsZip = async (getAllImg) => {
    const zip = new JSZip();
    const imageUrls = [getAllImg.Image1, getAllImg.Image2, getAllImg.Image3];
    await Promise.all(
      imageUrls.map(async (url, index) => {
        try {
          const response = await axios.get(url, {
            responseType: "arraybuffer",
          });
          zip.file(`image${index + 1}.png`, response.data, { binary: true });
        } catch (error) {
          console.error(`Error downloading image ${index + 1}:`, error);
        }
      })
    );

    zip.generateAsync({ type: "blob" }).then((content) => {
      const zipFile = new Blob([content], { type: "application/zip" });
      const zipFileUrl = URL.createObjectURL(zipFile);
      const link = document.createElement("a");
      link.href = zipFileUrl;
      link.setAttribute("download", "I11-Creations.zip");
      document.body.appendChild(link);
      link.click();
    });
  };

  let lastCopiedText = null;
  const copyText = (textData) => {
    if ("clipboard" in navigator) {
      navigator.clipboard
        .readText()
        .then((clipboardData) => {
          if (clipboardData !== textData) {
            navigator.clipboard.writeText(textData).then(() => {
              toast.success("Copied", {
                hideProgressBar: true,
                closeButton: false,
                style: {background: "#D6FAE4", border:"1px solid #58C76A", minHeight:"1px"},
                icon:<img alt="certified" src={Verified} />
              });
              lastCopiedText = textData;
            });
          }
        })
        .catch((error) => {
          console.error("Error reading or writing to clipboard: ", error);
        });
    } else {
      if (lastCopiedText !== textData) {
        var textField = document.createElement("textarea");
        textField.innerText = textData;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
        toast.success("Copied", {
          hideProgressBar: true,
          closeButton: false,
          style: {background: "#D6FAE4", border:"1px solid #58C76A", minHeight:"1px"},
          icon:<img alt="certified" src={Verified} />
        });

        lastCopiedText = textData;
      } else {
      }
    }
  };

  const active = (data) => {
    setActiveClass(data);
    setdetailedHistory(MyCreationApiValues[data]);
    getUploadStatusApi(MyCreationApiValues[data]);
    scrollToHistoryResponse()
  };

  const myCreations = (pageValue) => {
    const payload = {
      page: pageValue || page,
      per_page: 5,
    };
    if (payload.page === 1) {
      setLoading(true);
    } else {
      setpaginationloading(true);
    }

    mycreationswithPagination(payload)
      .then((res) => {
        console.log(res);
        if (res && res.data && res.data.length) {
          setMyCreationApiValues((data) => [...data, ...res.data]);
          if (payload.page === 1) {
            setdetailedHistory(res.data[0]);
            getUploadStatusApi(res.data[0]);
          }
          settotal_Count(res.data[0].total_count);
          setLoading(false);
          setpaginationloading(false);
        } else {
          setLoadmessage(true);
          setLoading(false);
          setpaginationloading(false);
        }
        return res;
      })
      .catch((err) => {
        return err;
      });
    // myCreation()
    //   .then((res) => {
    //     console.log(res);
    //     if (res && res.data && res.data.length) {
    //       console.log(res.data);
    //       setMyCreationApiValues(res.data);
    //       setdetailedHistory(res.data[0]);
    //       setLoading(false);
    //     } else {
    //     }
    //     return res;
    //   })
    //   .catch((err) => {
    //     return err;
    //   });
  };
  const getMoreContent = () => {
    if (!paginationloading) {
      myCreations(page + 1);
      setpage(page + 1);
    }
  };
  useEffect(() => {
    myCreations();
  }, []);
  const getUploadStatusApi = (data) => {
    if (!data?.Image_Output[0].has_image) {
      const payload = {
        request_id: detailedHistory.Image_Output[0].Request_id,
      };
      uploadstatus(payload)
        .then((res) => {
          console.log(res, "upload images ");
          detailedHistory.Image_Output[0].Image1 = res.Image[0];
          detailedHistory.Image_Output[0].Image2 = res.Image[1];
          detailedHistory.Image_Output[0].Image3 = res.Image[2];
          detailedHistory.Image_Output[0].has_image = res.data.has_image;
        })
        .catch((err) => {
          toast.error(err.message, {
            hideProgressBar: true,
            closeButton: false,
            style: {background: "#FAD6D6", border:"1px solid #C75858", minHeight:"1px"},
            icon:<img alt="info" src={Failure} />
          });
        })
        .finally(() => {
          setTimeout(getUploadStatusApi, 4000);
        });
    }
  };

  const HistoryResponse = () => {
    return (
      <div>
        {loadmessage ? (
          <div className="labelForm">No content to be displayed</div>
        ) : (
          <div className="col-md-12 col-xl-12 col-lg-12">
            <div className="marginTop mb-5">
              <div className="">
                <div className="d-flex">
                  <div className="col">
                    <h1 className="heading m-0">{detailedHistory?.data}</h1>
                  </div>
                  <div className="float-end mt-1">
                    <span className="linktxt numCreds">
                      <img
                        src={CreditsIcon}
                        alt="Credits"
                        className="mx-1 mb-1"
                      />
                      <span className="numCred me-1">
                        {detailedHistory?.Credits_used}
                      </span>
                      <span className="cred me-1">Credits</span>
                    </span>
                  </div>
                </div>
                <div>
                  {/* <p className="head3 mt-4">Prompts</p>
                <div className="">
                  <table className="col-md-12">
                    <tbody>
                      <tr className="border-bottom">
                        <td className="tag">Write an ad for</td>
                        <td className="description">{detailedHistory.data}</td>
                      </tr>
                      <tr className="border-bottom">
                        <td className="tag">Image Description</td>
                        <td className="description">
                          {detailedHistory.Image_Input}
                        </td>
                      </tr>
                      <tr className="border-bottom">
                        <td className="tag">Image Type</td>
                        <td className="description">{detailedHistory.Style}</td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}
                </div>
                {detailedHistory?.Text_Output.map((item, i) => (
                  <>
                    <div className="d-flex flex-column mt-4 flex-sm-row">
                      <div className="col d-flex flex-sm-col">
                        <h4 className="me-3 head3">Content</h4>{" "}
                        <span className="labelForm lblColor mt-1 ">
                          Option {detailedHistory?.Text_Output.length - i}
                        </span>
                      </div>
                      <div>
                      <button
                        className="btnCopy sm-float-end"
                        onClick={copyText.bind(this, item.data)}
                      >
                        <div className="d-flex">
                          <img
                            src={CopyIcon}
                            alt="Copy Icon"
                            className="me-2 copyImage"
                          />
                          <span className="iconTxt">Copy</span>
                        </div>
                      </button>
                      </div>
                    </div>
                    <div className="border-bottom">
                      <table className="col-md-12">
                        <tbody>
                          <tr className="">
                            <td className="tag">Write an ad for </td>
                            <td className="description">{item.name}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    
                    <div className="mt-3">
                      <textarea
                        className="brief w-100 textHeight"
                        value={item.data}
                        readOnly
                        name="description"
                      ></textarea>
                    </div>
                  </>
                ))}
                {detailedHistory?.Image_Output.map((item, i) => (
                  <>
                    <div className="d-flex mt-4">
                      <div className="col d-flex">
                        <h4 className="me-2 head3">Image Results</h4>{" "}
                        <div className="options">
                          <span className="labelForm lblColor mt-1">
                            Option {detailedHistory?.Image_Output.length - i}
                          </span>
                        </div>
                      </div>
                      {item.has_image ? (
                        <button
                          className="btnCopy"
                          onClick={downloadImagesAsZip.bind(this, item)}
                        >
                          <div className="d-flex">
                            <img
                              src={DownloadIcon}
                              alt="Copy Icon"
                              className="me-2 downloadImg"
                            />
                            <span className="iconTxt">Download All</span>
                          </div>
                        </button>
                      ) : null}
                    </div>
                    <div className="border-bottom">
                      <table className="col-md-12">
                        <tbody>
                          <tr className="">
                            <td className="tag">Image Description</td>
                            <td className="description">{item.name}</td>
                          </tr>
                         
                        </tbody>
                      </table>
                    </div>
                    <div className="border-bottom">
                      <table className="col-md-12">
                        <tbody>
                          
                          <tr className="">
                            <td className="tag">Image Type</td>
                            <td className="description">{item.type}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex flex-wrap mt-3">
                      <div className="me-6 mt-3 mt-md-0 col">
                        <img
                          src={item.Thumb0}
                          alt="Image1"
                          className="imgWidth"
                        />
                        <div className="mt-3">
                          {item.Image1 ? (
                            <button
                              className="downld"
                              onClick={downloadImage.bind(this, item.Image1)}
                            >
                              <img
                                src={DownloadIcon}
                                alt="Image1"
                                className="downloadImg"
                              />
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <div className="me-6 mt-3 mt-md-0 col">
                        <img
                          src={item.Thumb1}
                          alt="Image2"
                          className="imgWidth"
                        />
                        <div className="mt-3">
                          {item.Image2 ? (
                            <button
                              className="downld"
                              onClick={downloadImage.bind(this, item.Image2)}
                            >
                              <img
                                src={DownloadIcon}
                                alt="Image1"
                                className="downloadImg"
                              />
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <div className="me-6 mt-3 mt-md-0 col">
                        <img
                          src={item.Thumb2}
                          alt="Image3"
                          className="imgWidth"
                        />
                        <div className="mt-3">
                          {item.Image1 ? (
                            <button
                              className="downld"
                              onClick={downloadImage.bind(this, item.Image3)}
                            >
                              <img
                                src={DownloadIcon}
                                alt="Image1"
                                className="downloadImg"
                              />
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const ApiValues = () => {
    return (
      <div>
        {loadmessage ? (
          <div className="labelForm">No content to be displayed</div>
        ) : (
          <InfiniteScroll
            dataLength={MyCreationApiValues?.length}
            next={getMoreContent}
            hasMore={MyCreationApiValues?.length < totalCount}
            loader={
              <div className="d-flex justify-content-center">
                <img src={paginationloader} alt="Loading..." />
              </div>
            }
            endMessage={
              <span className="d-flex justify-content-center tab inActiveClr mt-5 mb-2">
                --End of Results--
              </span>
            }
          >
            <div className="cursorPointer">
              {MyCreationApiValues?.map((item, index) => (
                <li
                  className={
                    activeClass === index
                      ? "myCreations button"
                      : "myCreations inActiveClr button"
                  }
                  key={index}
                >
                  <div className="mt-3" onClick={(event) => active(index)}>
                    <div className="labelForm lblColor">
                      {moment(item.Date).format("DD MMM YYYY")}
                    </div>
                    <h3 className="mt-3 head3"> {item.data}</h3>
                    <p className="brief truncate-line-clamp">
                      {item.Text_Output[0].data}
                    </p>
                    <div className="d-flex flex-wrap">
                      <span className="me-2">
                        <img
                          src={activeClass === index ? ImagesIcon : ImagesGrey}
                          alt="Create Icon"
                          className="me-2"
                        />
                        <span className="tab">
                          {Object.keys(item.Image_Output).length * 3} Images
                        </span>
                      </span>
                      <span className="me-2">
                        <img
                          src={
                            activeClass === index ? ContentIcon : ContentGrey
                          }
                          alt="Create Icon"
                          className="me-2"
                        />
                        <span className="tab">
                          {item.Text_Output.length}{" "}
                          {item.Text_Output.length > 1
                            ? "Content options"
                            : "Content option"}
                        </span>
                      </span>
                      <span className="me-2">
                        <img
                          src={
                            activeClass === index ? CreditsIcon : CreditsInact
                          }
                          alt="Create Icon"
                          className="me-2"
                        />
                        <span className="tab">{item.Credits_used} Credits</span>
                      </span>
                    </div>
                    <div className="border-top mt-3"></div>
                  </div>
                </li>
              ))}
            </div>
          {/* }
          endMessage={
            <span className="d-flex justify-content-center tab inActiveClr mt-5">
              --End of Results--
            </span>
          }
        > */}
          <div className="cursorPointer">
            {MyCreationApiValues?.map((item, index) => (
              <li
                className={
                  activeClass === index
                    ? "myCreations button"
                    : "myCreations inActiveClr button"
                }
                key={index}
              >
                <div className="mt-3" onClick={(event) => active(index)}>
 
                  <div className="labelForm lblColor">
                    {moment(item.Date).format("DD MMM YYYY")}
                  </div>
                  <h3 className="mt-3 head3"> {item.data}</h3>
                  <p className="brief truncate-line-clamp">
                    {item.Text_Output[0].data}
                  </p>
                  <div className="d-flex flex-wrap">
                    <span className="me-2">
                      <img
                        src={activeClass === index ? ImagesIcon : ImagesGrey}
                        alt="Create Icon"
                        className="me-2"
                      />
                      <span className="tab">
                        {Object.keys(item.Image_Output).length * 3} Images
                      </span>
                    </span>
                    <span className="me-2">
                      <img
                        src={
                          activeClass === index ? ContentIcon : ContentGrey
                        }
                        alt="Create Icon"
                        className="me-2"
                      />
                      <span className="tab">
                        {item.Text_Output.length}{" "}
                        {item.Text_Output.length > 1
                          ? "Content options"
                          : "Content option"}
                      </span>
                    </span>
                    <span className="me-2">
                      <img
                        src={
                          activeClass === index ? CreditsIcon : CreditsInact
                        }
                        alt="Create Icon"
                        className="me-2"
                      />
                      <span className="tab">{item.Credits_used} Credits</span>
                    </span>
                  </div>
                  <div className="border-top mt-3"></div>
                </div>
              </li>
            ))}
          </div>
        </InfiniteScroll>
        )}
      </div>
    );
  };

  const History = () => {
    return (
      <div>
        <NavbarMenu/>
        <div className="container">
          <div className="scrollable-container">
            {/* <div className="col-md-5 col-xl-5 borderEnd left scrollable-div "  ref={leftDivRef} onScroll={handleLeftScroll}> */}
            <div className= "col-md-5 col-xl-5 scrollable-div borderEnd" ref={leftDivRef} >
              <div className="width marginTop">
                <div>
                  <Link to="/newAd">
                    <button className="heading">
                      <span>
                        <img className="arrow" src={backArrow} />
                      </span>
                      Back
                    </button>
                  </Link>
                </div>
                <div className="d-flex flex-col">
                  <div className="col">
                    <h1 className="heading m-0">My Creations</h1>
                  </div>{" "}
                  <div className="float-end mt-1 textStyle">
                    <Link to="/newAd">
                      <img
                        src={CreateIcon}
                        alt="Create Icon"
                        className="me-1 createImg"
                      />
                      <span className="labelForm">Create New Ad</span>
                    </Link>
                  </div>
                </div>
                <ApiValues scrollToHistoryResponse={scrollToHistoryResponse}/>
              </div>
            </div>
            <div className="scrollable-div" ref={rightDivRef}>
            <div ref={historyResponseRef}>                
            <HistoryResponse />      
            </div>    
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {isLoading ? (
        <div className="container loadertrans">
          <div className="loaderWidth">
            <Lottie animationData={LoadingSpinner} />
          </div>
        </div>
      ) : loadmessage ? (
        <>
          <div>
            <NavbarMenu />{" "}
            <div className="loadmessage">
              No content to display . To create a new Ad, please 
              <span className="cursorPointer ">
                <a href="/newAd" className="clickherebtn">Click here  </a>
              </span>
            </div>{" "}
          </div>
        </>
      ) : (
        <History />
      )}
    </>
  );
};
export default MyCreations;

// import React, { useRef } from "react";

// const MyCreations = () => {
//   const historyResponseRef = useRef(null);

//   const scrollToHistoryResponse = () => {
//     if (historyResponseRef.current) {
//       historyResponseRef.current.scrollIntoView({ behavior: "smooth" });
//     }
//   };

//   return (
//     <div>
//       {/* Your other components */}
//       <ApiValues scrollToHistoryResponse={scrollToHistoryResponse} />
//       {/* Your other components */}
//       <div ref={historyResponseRef}>
//         {/* Content of the HistoryResponse */}
//       </div>
//     </div>
//   );
// };

// const ApiValues = ({ scrollToHistoryResponse }) => {
//   return (
//     <div>
//       {/* Your ApiValues content */}
//       <button onClick={scrollToHistoryResponse}>
//         Scroll to History Response
//       </button>
//       {/* Rest of your component */}
//     </div>
//   );
// };

// export default MyCreations;
