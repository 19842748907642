import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Info } from "../../assets/images";
const axiosWithInterceptor = axios.create();

// Request interceptor for API calls
axiosWithInterceptor.interceptors.request.use(
  async (config) => {
    config.headers = {
      "X-Access-Token": `${sessionStorage.getItem("token")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosWithInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    console.log(error.response.status);
    if (
      (error.response.status === 403 && !originalRequest._retry) ||
      (error.response.status === 401 && !originalRequest._retry)
    ) {
      originalRequest._retry = true;

      await refreshtoken()
        .then((res) => {
          console.log("first");
        })
        .catch((err) => {
          console.log(err);
          // const navigate = useNavigate()
          //   navigate("/newAd")
        });
      axios.defaults.headers.common["X-Access-Token"] =
        sessionStorage.getItem("token");
      return axiosWithInterceptor(originalRequest);
    }
    return Promise.reject(error);
  }
);

// export const refreshtoken = async ()=>{
//   const rt =sessionStorage.getItem("rtoken")
//   const res = await axiosWithInterceptor.post("https://api.i11labs.com/advert/oauth/getRefreshToken", { refresh_token: rt });
//   sessionStorage.setItem('rtoken', res.data.refresh_token);
//   sessionStorage.setItem('token', res.data.access_token);
//   return res;
// }
export const refreshtoken = async () => {
  const rt = sessionStorage.getItem("rtoken");
  return axios
    .post("https://api.i11labs.com/advert/oauth/getRefreshToken", {
      refresh_token: rt,
    })
    .then((res) => {
      sessionStorage.setItem("rtoken", res.data.refresh_token);
      sessionStorage.setItem("token", res.data.access_token);
      return res;
    })
    .catch((err) => {
      toast.warn("Session Expired", {
        hideProgressBar: true,
        closeButton: false,
        style: {background: "#FFFFEE", border:"1px solid #EBC035", minHeight:"1px"},
        icon:<img alt="info" src={Info} />
      });
      window.location.href = "/login";
    });
};
export const loginApi = async (data) => {
  const res = await axiosWithInterceptor.post(
    "https://api.i11labs.com/advert/login",
    data,
    {}
  );
  return res;
};

export const getCredits = async () => {
  const res = await axiosWithInterceptor.post(
    "https://api.i11labs.com/advert/get_credits",
    null
  );
  return res;
};
export const generateAd = async (data) => {
  const res = await axiosWithInterceptor.post(
    "https://api.i11labs.com/advert/dataimg",
    data
  );
  return res;
};
export const textRetryApi = async (data) => {
  const res = await axiosWithInterceptor.post(
    "https://api.i11labs.com/advert/data",
    data
  );
  return res;
};
export const imageRetryApi = async (data) => {
  const res = await axiosWithInterceptor.post(
    "https://api.i11labs.com/advert/image",
    data
  );
  return res;
};
export const myCreation = async () => {
  const res = await axiosWithInterceptor.post(
    "https://api.i11labs.com/advert/creations",
    null
  );
  return res;
};
export const revoketoken = async (data) => {
  const res = await axiosWithInterceptor.post(
    "https://api.i11labs.com/advert/oauth/revoke_token",
    data
  );
  return res;
};

export const mycreationswithPagination = async (data) => {
  const res = await axiosWithInterceptor.post(
    `https://api.i11labs.com/advert/Paginate?per_page=${data.per_page}&page=${data.page}`,
    null
  );
  return res;
};

export const uploadstatus = async (data) => {
  const res = await axiosWithInterceptor.post(
    "https://api.i11labs.com/advert/upload_status",
    data
  );
  return res;
};
