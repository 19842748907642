import React from "react";
import NavbarMenu from "../../Components/Navbar/Navbar";
import ShowHidePassword from "../../Components/ShowHidePassword/ShowHidePassword";

import "./UpdatePassword.scss";

const UpdatePassword = () => {
  return (
    <div className="">
      <NavbarMenu />
      <div className="container">
        <h1 className="heading mt-5">Update Password</h1>
        <div className="content m-0">
          <form>
            <div className="row">
              <div className="col-md-12">
                <div className="padding">
                  <label className="labelForm">Old Password</label>
                  <ShowHidePassword name="Old Password" placeholder="Enter Old Password"/>
                  <label className="labelForm">New Password</label>
                  <ShowHidePassword name="New Password" placeholder="Enter New Password"/>
                  <label className="labelForm">Confirm Password</label>
                  <ShowHidePassword name="Confirm Password" placeholder="Enter Confirm Password"/>
                  <div className="">
                    <button className="saveBtn">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
