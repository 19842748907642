import React from "react";
import { LogoIcon, PasswordIcon } from "../../assets/images";
import ShowHidePassword from "../../Components/ShowHidePassword/ShowHidePassword";
import "./NewPassword.scss";

const NewPassword = () => {
  return (
    <div className="container">
      <div className="title">
        <h1>
          <img src={LogoIcon} alt="logo" />
        </h1>
      </div>
      <div className="content">
        <form>
          <div className="row">
            <div className="col-md-12">
              <div className="emoji">
                <img src={PasswordIcon} alt="emoji" />
              </div>
              <h1 className="heading">Create New Password</h1>
              <label className="labelForm">New Password</label>
              <ShowHidePassword
                name="New Password"
                placeholder="Enter New Password"
              />
              <label className="labelForm">Confirm Password</label>
              <ShowHidePassword
                name="Confirm Password"
                placeholder="Enter Confirm Password"
              />
              <div className="">
                <button className="saveBtn">Save</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewPassword;
