import React from "react";
import { LogoIcon, MailIcon } from "../../assets/images";
import "./CheckEmail.scss";

const CheckEmail = () => {
  return (
    <div className="container">
      <div className="title">
        <h1>
          <img src={LogoIcon} alt="logo" />
        </h1>
      </div>
      <div className="transform checkWidth">
        <div className="row">
          <div className="col-md-9">
            <div className="emoji">
              <img src={MailIcon} alt="Mail" />
            </div>
            <div className="main">
              <h1 className="heading">Check your inbox.</h1>
              <p className="labelForm">
                We've sent an email to 'demo@gmail.com' for verification.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="textBottom">
        <a
          href="https://mail.google.com/mail/u/0/#inbox"
          className="labelForm link"
        >
          Check Email
        </a>
      </div>
    </div>
  );
};

export default CheckEmail;
