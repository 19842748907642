import React from "react";
import { SmileIcon, LogoIcon, IllusImage } from "../../assets/images";
import ShowHidePassword from "../../Components/ShowHidePassword/ShowHidePassword";
import "./SignUp.scss";
import { Link } from "react-router-dom";

const SignUp = () => {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="loginImg">
          <img src={IllusImage} alt="" className="d-none d-md-block w-100" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="content-section">
          <form>
            <div className="">
              <div className="">
                <div className="emoji">
                  <img src={SmileIcon} alt="emoji" />
                </div>
                <h1 className="heading">Create an account</h1>
                <label className="labelForm">Name</label>
                <div className="inputBox">
                  <input
                    placeholder="yourname"
                    className="form-control w-100"
                  />
                </div>
                <label className="labelForm">Email Address</label>
                <div className="inputBox">
                  <input
                    placeholder="yourname@example.com"
                    className="form-control w-100"
                  />
                </div>
                <label className="labelForm">Password</label>
                <ShowHidePassword
                  name="Password"
                  placeholder="Enter Password"
                />
                <div className="mt-3">
                  <button className="regBtn">Register</button>
                </div>
                <div className="mt-4 labelForm text-center">
                  <span>Already have an account?</span>&nbsp;&nbsp;
                  <span className="textDecor">
                    <Link to="/signUp">Login</Link>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
