import React, { useEffect, useState } from "react";
import {
  SmileIcon,
  ShowIcon,
  HideIcon,
  MainLogo,
  BnrImg,
  Failure,
} from ".././../assets/images";
import LoadingSpinner from "../../assets/Loader.json";
import Lottie from "lottie-react";
import "./Login.scss";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import md5 from "md5";

const Login = () => {
  const [code] = useSearchParams();

  const Url =
  "https://auth.i11labs.com/server/oauth2/authorize?response_type=code&client_id=b4237dde-f720-405b-a6ed-976d025020a2&scope=openid&redirect_uri=https://app.i11labs.com/login&state="
  const [generatedState, setgeneratedState] = useState("");
  const [isLoading, setLoading] = useState(false);

  let navigate = useNavigate();

  const Datass = () => {
    const [emailId, setemailId] = useState("");
    const [passWord, setpassWord] = useState("");
    const [isVisible, setVisible] = useState(false);
    const toggle = () => {
      setVisible(!isVisible);
    };
    const login = () => {
      setLoading(true);
      axios
        .post("http://172.105.53.192:8080/advert/login", {
          emailId: emailId,
          password: md5(passWord),
        })
        .then((res) => {
          if (res.data.status === "success") {
            sessionStorage.setItem("token", res.data.access_Token);
            navigate("/newAd");
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.status, {
            hideProgressBar: true,
            closeButton: false,
            style: {background: "#FAD6D6", border:"1px solid #C75858", minHeight:"1px"},
            icon:<img alt="info" src={Failure} />
          });
        });
    };
    function makeid(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      sessionStorage.setItem("State",result)
      return result;
    }


    const AuthFlow =()=>{
      if(code.get("code")&&code.get("state")&&code.get("state")==sessionStorage.getItem("State")){
        axios
        .post("https://api.i11labs.com/advert/oauth/getToken", {
          auth_code:code.get("code")
        })
        .then((res) => {
         sessionStorage.setItem("token",res.data.access_token);
         sessionStorage.setItem("rtoken",res.data.refresh_token)
         navigate('/newAd')
        })
        .catch((err) => {
       
        });
      }
      else{
        window.location.replace(Url + makeid(40));
      }
    }

    useEffect(() => {
      AuthFlow() },[code]);

    return (
      <></>
      // <div className="container-fluid">
      //   <div className="row">
      //     <div className="col-lg-7 col-xl-6 p-0 d-none d-lg-block">
      //       <div className="">
      //         <img src={BnrImg} alt="" className="vh-100 dWidth" />
      //       </div>
      //       <div className="top-left">
      //         <img src={MainLogo} alt="" />
      //       </div>
      //     </div>
      //     <div className="col-lg-5 p-0">
      //       <div className="content-section">
      //         <form className="">
      //           <div className="emoji">
      //             <img src={SmileIcon} alt="emoji" />
      //           </div>
      //           <h1 className="heading">Hi, there!</h1>
      //           <label className="labelForm">Email Address</label>
      //           <div className="inputBox">
      //             <input
      //               placeholder="yourname@example.com"
      //               className="form-control w-100 formInput"
      //               value={emailId}
      //               onChange={(e) => setemailId(e.target.value)}
      //             />
      //           </div>
      //           <label className="labelForm">Password</label>
      //           <input
      //             type={isVisible ? "text" : "password"}
      //             name="Password"
      //             value={passWord}
      //             onChange={(e) => {
      //               setpassWord(e.target.value);
      //             }}
      //             className="form-control w-100 formInput"
      //             placeholder="Enter Password"
      //           />
      //           <span>
      //             <img
      //               src={isVisible ? ShowIcon : HideIcon}
      //               alt="Hide"
      //               className="HideImage"
      //               onClick={toggle}
      //             />
      //           </span>
      //           <div className="mt-2">
      //             <span className="labelForm">Forgot Password?</span>
      //           </div>
      //           <div className="mt-3">
      //             <button className="loginBtn" onClick={login}>
      //               Login
      //             </button>
      //           </div>
      //           <div className="mt-4 labelForm text-center">
      //             <span>New User?</span>&nbsp;&nbsp;
      //             <span className="textDecor">
      //               <Link to="/signUp">Create an account</Link>
      //             </span>
      //           </div>
      //         </form>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="container loaderTrans">
          <div className="loaderWidth">
            <Lottie animationData={LoadingSpinner} />
          </div>
        </div>
      ) : (
        <Datass />
      )}
    </>
  );
};

export default Login;