import React from "react";
import { VerifyIcon, LogoIcon } from "../../assets/images";
import "./AccountVerify.scss";

const AccountVerify = () => {
  return (
    <div className="container">
      <div className="title">
        <h1>
          <img src={LogoIcon} alt="logo" />
        </h1>
      </div>
      <div className="transform">
        <div className="row">
          <div className="col-md-12">
            <img src={VerifyIcon} alt="verify logo" />
            <h2 className="heading">Account verified!</h2>
            <p className="labelForm">You've verified your account.</p>
            <div>
              <button className="loginBtn">Proceed to Login</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountVerify;
