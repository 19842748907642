import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { toast } from "react-toastify";
import JSZip from "jszip";
import axios from "axios";
import {
  CopyIcon,
  CopyInactive,
  DownloadIcon,
  DownloadInactive,
  Failure,
  RetryIcon,
  RetryInactive,
  Verified,
} from "../../assets/images";
import ImageLoader from "../../assets/thinkingloader.json";
import Lottie from "lottie-react";
import "../../Pages/AdPage/AdPage.scss";
import {
  imageRetryApi,
  textRetryApi,
  uploadstatus,
} from "../../Pages/ApiService/ApiService";
//import { saveAs } from "file-saver";
const AdResponse = forwardRef(
  (
    {
      data,
      imgss,
      inputData,
      description,
      parentId,
      imageType,
      retrycall,
      has_image,
      req_id,tooManyRequestHandler
    },
    ref
  ) => {
    const [textResponse, settextResponse] = useState(data);
    // const SeparateImagesArray = Math.floor(imgss.length / 2);

    const [imagesResponse, setimagesResponse] = useState(imgss);
    const [Downloadimages, setDownloadimages] = useState([]);

    const [textLoading, setLoading] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [error, seterror] = useState("");

    let lastCopiedText = null;
    const copyText = () => {
      if ("clipboard" in navigator) {
        navigator.clipboard
          .readText()
          .then((clipboardData) => {
            if (clipboardData !== textResponse) {
              navigator.clipboard.writeText(textResponse).then(() => {
                toast.success("Copied", {
          hideProgressBar: true,
          closeButton: false,
          style: {background: "#D6FAE4", border:"1px solid #58C76A", minHeight:"1px"},
          icon:<img alt="certified" src={Verified} />
        });
                lastCopiedText = textResponse;
              });
            }
          })
          .catch((error) => {
            console.error("Error reading or writing to clipboard: ", error);
          });
      } else {
        if (lastCopiedText !== textResponse) {
          var textField = document.createElement("textarea");
          textField.innerText = textResponse;
          document.body.appendChild(textField);
          textField.select();
          document.execCommand("copy");
          textField.remove();
          toast.success("Copied", {
          hideProgressBar: true,
          closeButton: false,
          style: {background: "#D6FAE4", border:"1px solid #58C76A", minHeight:"1px"},
          icon:<img alt="certified" src={Verified} />
        });

          lastCopiedText = textResponse;
        } else {
        }
      }
    };

    useImperativeHandle(ref, () => ({
      textRetry() {
        RetryText();
      },
      imageRetry() {
        RetryImage();
      },
    }));
    const RetryText = () => {
      setLoading(true);
      textRetryApi({
        data: inputData,
        parent_id: parentId,
      })
        .then((res) => {
          retrycall();
          settextResponse(res.data.Data);
          setLoading(false);
        })
        .catch((err) => {
          seterror(err);
          toast.danger(error, {
            hideProgressBar: true,
            closeButton: false,
            style: {background: "#FAD6D6", border:"1px solid #C75858", minHeight:"1px"},
            icon:<img alt="info" src={Failure} />
          });
        });
    };

    const RetryImage = () => {
      setImageLoading(true);
      imageRetryApi({
        image: description,
        style: imageType,
        parent_id: parentId,
      })
        .then((res) => {
          req_id = res.data.Request_id;
          retrycall();
          //const SeparateImagesArray = Math.floor(imgss.length / 2);
          setimagesResponse(res.data.Image);
          getUploadStatusApi();
          //setDownloadimages(res.data.Image.slice(0, SeparateImagesArray));
          setImageLoading(false);
        })
        .catch((err) => {
          seterror(err);
          toast.danger(error, {
            hideProgressBar: true,
            closeButton: false,
            style: {background: "#FAD6D6", border:"1px solid #C75858", minHeight:"1px"},
            icon:<img alt="info" src={Failure} />
          });
        });
    };

    const downloadImage = (getImage) => {
      const link = document.createElement("a");
      link.href = Downloadimages[getImage];
      link.download = "image.png";
      link.click();
      link.remove()
    };

    const downloadImagesAsZip = async (getAllImg) => {
      const zip = new JSZip();
      await Promise.all(
        getAllImg.map(async (url, index) => {
          try {
            const response = await axios.get(url, {
              responseType: "arraybuffer",
            });
            zip.file(`image${index + 1}.png`, response.data, { binary: true });
          } catch (error) {
            console.error(`Error downloading image ${index + 1}:`, error);
          }
        })
      );

      zip.generateAsync({ type: "blob" }).then((content) => {
        const zipFile = new Blob([content], { type: "application/zip" });
        const zipFileUrl = URL.createObjectURL(zipFile);
        const link = document.createElement("a");
        link.href = zipFileUrl;
        link.setAttribute("download", "I11-Creations.zip");
        document.body.appendChild(link);
        link.click();
      });
    };

    const getUploadStatusApi = () => {
      if (!has_image) {
        const payload = {
          request_id: req_id,
        };
        uploadstatus(payload)
          .then((res) => {
            console.log(res, "upload images");
            setDownloadimages(res.data.Images);
            has_image = res.data.has_image;
            tooManyRequestHandler(res.data.has_image)
          })
          .catch((err) => {
            has_image = false;
            toast.error(err.message, {
              hideProgressBar: true,
              closeButton: false,
              style: {background: "#FAD6D6", border:"1px solid #C75858", minHeight:"1px"},
              icon:<img alt="info" src={Failure} />
            });
          })
          .finally(() => {
            setTimeout(getUploadStatusApi, 4000);
          });
      }
    };
    useEffect(() => {
      getUploadStatusApi();
      console.log(has_image);

      //   console.log(data);
      //   if (!has_image) {
      //     const payload = {
      //       request_id: req_id,
      //     };
      //     uploadstatus(payload)
      //       .then((res) => {
      //         console.log(res, "upload images ");
      //         setDownloadimages(res.data.Images);
      //         data.has_image = res.data.has_image;
      //       })
      //       .catch((err) => {
      //         has_image = false;
      //         toast.error(err.message);
      //       });
      //   }
      // }, 20000);
    }, [has_image]);

    return (
      <div className="col-md-12 col-xl-7">
        <div className="float-xl-end marginTop">
          <div className="sideWidth">
            <h1 className="heading p-0 m-0">Ad results will appear here</h1>
            <div className="d-flex mt-4">
              <div className="col">
                <h6 className="m-0 subTitle">Content</h6>
              </div>
              <div className="float-end">
                <div className="d-flex">
                  <button
                    className="btnCopy me-2"
                    onClick={RetryText}
                    disabled={textLoading}
                  >
                    <div className="d-flex">
                      <img
                        src={textLoading ? RetryInactive : RetryIcon}
                        alt="Retry Icon"
                        className="me-2"
                      />
                      <span className="iconTxt">Retry</span>
                    </div>
                  </button>
                  <button
                    className="btnCopy"
                    onClick={copyText}
                    disabled={textLoading}
                  >
                    <div className="d-flex">
                      <img
                        src={textLoading ? CopyInactive : CopyIcon}
                        alt="Copy Icon"
                        className="me-2 copyImage"
                      />
                      <span className="iconTxt">Copy</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-2">
              {textLoading ? (
                <div className="container textLoader">
                  <div className="loaderWdth">
                    <Lottie animationData={ImageLoader} />
                    <p className="think mt-2">Thinking...</p>
                  </div>
                </div>
              ) : (
                <textarea
                  className="p-4 border w-100 textHeight"
                  value={textResponse}
                  readOnly
                  name="description"
                />
              )}
            </div>
            <div className="d-flex mt-4">
              <div className="col">
                <h6 className="m-0 subTitle">Image Results</h6>
              </div>
              <div className="float-end">
                <div className="d-flex">
                  <button
                    className="btnCopy me-2"
                    onClick={RetryImage}
                    disabled={imageLoading}
                  >
                    <div className="d-flex">
                      <img
                        src={imageLoading ? RetryInactive : RetryIcon}
                        alt="Retry Icon"
                        className="me-2"
                      />
                      <span className="iconTxt">Retry</span>
                    </div>
                  </button>
                  {Downloadimages ? (
                    <button
                      className="btnCopy"
                      onClick={downloadImagesAsZip.bind(this, Downloadimages)}
                      disabled={imageLoading}
                    >
                      <div className="d-flex">
                        <img
                          src={imageLoading ? DownloadInactive : DownloadIcon}
                          alt="Copy Icon"
                          className="me-2"
                        />
                        <span className="iconTxt">Download All</span>
                      </div>
                    </button>
                  ) : (
                    <button className="btnCopy retry" disabled={imageLoading}>
                      <div className="d-flex">
                        <img
                          src={!imageLoading ? DownloadInactive : DownloadIcon}
                          alt="Copy Icon"
                          className="me-2"
                        />
                        <span className="retry">Download All</span>
                      </div>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap mt-3 mb-5">
              {imagesResponse.map((item, index) => (
                <div className="me-6 mt-3 mt-md-0 col" key={index}>
                  {imageLoading ? (
                    <div className="imageLoader">
                      <div className="loaderWdth">
                        <Lottie animationData={ImageLoader} />
                        <p className="think mt-2">Thinking...</p>
                      </div>
                    </div>
                  ) : (
                    <img src={item} alt="Image1" className="imgWidth" />
                  )}
                  {!imageLoading && Downloadimages ? (
                    <div className="mt-3">
                      <button className="downld">
                        <img
                          src={DownloadIcon}
                          alt="Image2 downloadImg"
                          onClick={downloadImage.bind(this, index)}
                        />
                      </button>
                    </div>
                  ) : 
                  <div className="mt-3 ">
                      <button className="downld">
                        <img
                          src={DownloadIcon}
                          alt="Image2 downloadImg"
                        />
                      </button>
                    </div>}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default AdResponse;
