import React, { useRef } from "react";
import NewAddComponent from "../../Components/AdComponent/AddComponent";
import NavbarMenu from "../../Components/Navbar/Navbar";
import "./AdPage.scss";

const AdPage = () => {
  const navRef = useRef();
  const CreditUpdate = () => {
    navRef.current.updateCredit();
  }
   return (
    <div>
      <NavbarMenu  ref={navRef}/>
      <div className="container">
        <div className="display">
          <NewAddComponent creditUpdate={CreditUpdate}/>
        </div>
      </div>
    </div>
  );
};

export default AdPage;
