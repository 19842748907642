import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { LogoIcon, CreditsIcon, AvatarIcon, LogoutIcon, Failure } from "../../assets/images";
import "./Navbar.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "@popperjs/core";
import "bootstrap";
import { toast } from "react-toastify";
import { getCredits, revoketoken } from "../../Pages/ApiService/ApiService.js";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import ImageLoader from "../../assets/Pagination-Loader.gif"


const NavbarMenu = forwardRef((props, ref) => {
  const [credits, setCredits] = useState(0);
  const [error, seterror] = useState("");
  const [loading , setLoading] = useState(true);
  let navigate = useNavigate();

  const navLinkStyles = ({ isActive }) => {
    return {
      fontWeight: isActive ? "bold" : "medium",
    };
  };
  const CreditApicall = () => {
    getCredits()
      .then((res) => {
        setCredits(res.data.credits_available);
        setLoading(false);
      })
      .catch((err) => {
          // navigate("/");
          toast.error(err.response.data.message, {
            hideProgressBar: true,
            closeButton: false,
            style: {background: "#FAD6D6", border:"1px solid #C75858", minHeight:"1px"},
            icon:<img alt="info" src={Failure} />
          });
        seterror(err.response.data.message);
      });
  };
  useImperativeHandle(ref, () => ({
    updateCredit() {
      CreditApicall();
    },
  }));

  const logout = () => {
    revoketoken({
      token:sessionStorage.getItem("token")
    })
    .then((res)=>{
      console.log(res)
    })
    
window.location = "https://auth.i11labs.com/server/oauth2/public/logout";
  };

  useEffect(() => {
    CreditApicall();
  }, []);

  return (
    <div className="navBack">
      <div className="px-3 border-bottom">
        <nav className="navbar navbar-expand-lg ">
          <div className="container-fluid">
            <img src={LogoIcon} alt="logo" />
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className=" nav-link mt-2">
                 
                {loading ?
                 <span className="loadingText"> Loading <img src={ImageLoader} alt="loading" className="loading"/></span>:
                <span className="link-txt labelForm me-4 numCredits">
                    <img
                      src={CreditsIcon}
                      alt="Credits"
                      className="mx-1 mb-1"
                    />
                    <span className="numCred me-1">{credits}</span>
                    <span className="cred me-1">{credits>1?"Credits":"Credit"}</span>
                  </span>
                  }
                </li>
                {/* <li className="nav-link mt-2">
                <Link to ="/myCreations">
                <a href="/myCreations" className="nav-item link-text">
                  <span className=" me-4">My Creations</span>
                </a>
                </Link>
              </li> */}
                <NavLink to="/myCreations" style={navLinkStyles}>
                  {" "}
                  <span className="creations">My Creations</span>
                </NavLink>
                <li className="nav-link cursorPointer">
                  <div className="mt-2 mt-md-0">
                    <div
                      className="dropdown-toggle"
                      //data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={LogoutIcon}
                        alt="Avatar Logo"
                        className="imgRes"
                        onClick={logout}
                      />
                    </div>
                    {/* <ul className="dropdown-menu dropdown-menu-md-end">
                      {/* <li>
                      <div className="dropdown-item">
                        Update Password
                      </div>
                    </li> //
                      <li>
                        <div
                          className="dropdown-item dropItem"
                          onClick={logout}
                        >
                          Log out
                        </div>
                      </li>
                    </ul> */}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
});

export default NavbarMenu;