import React from "react";
import { CheckIcon, LogoIcon } from "../../assets/images";
import "./PasswordConfirm.scss";

const PasswordConfirm = () => {
  return (
    <div className="container">
      <div className="title">
        <h1>
          <img src={LogoIcon} alt="logo" />
        </h1>
      </div>
      <div className="transform">
        <div className="row">
          <div className="col-md-12">
            <img src={CheckIcon} alt="verify logo" />
            <h2 className="heading">Password changed</h2>
            <p className="labelForm mt-3">
              You've reset your password successfully.
            </p>
            <button className="proceedBtn mt-2">Proceed to Login</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordConfirm;
