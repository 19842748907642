import { Route, Routes } from "react-router-dom";
import CheckEmail from "./Pages/Inbox/CheckEmail";
import Login from "./Pages/Login/Login";
import MyCreations from "./Pages/MyCreations/MyCreations";
import AdPage from "./Pages/AdPage/AdPage";
import AccountVerify from "./Pages/AccountVerify/AccountVerify";
import NewPassword from "./Pages/NewPassword/NewPassword";
import PasswordConfirm from "./Pages/PasswordChanged/PasswordConfirm";
import SignUp from "./Pages/Register/SignUp";
import UpdatePassword from "./Pages/UpdatePassword/UpdatePassword";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signUp" element={<SignUp />} />
      <Route path="/accountVerify" element={<AccountVerify />} />
      <Route path="/createPassword" element={<NewPassword />} />
      <Route path="/confirmPassword" element={<PasswordConfirm />} />
      <Route path="/checkEmail" element={<CheckEmail />} />
      <Route path="/newAd" element={<AdPage />} />
      <Route path="/myCreations" element={<MyCreations />} />
      <Route path="/updatePassword" element={<UpdatePassword />} />
    </Routes>
  );
}

export default App;
