

import React, { useState, useRef, useEffect } from "react";
import AdResponse from "./AdResponse";
import Loadingspin from "../../assets/Pagination-Loader.gif"
import ImageLoader from "../../assets/thinkingloader.json";
import Lottie from "lottie-react";
import { toast } from "react-toastify";
import {
  CheckBlueIcon,
  CheckGreyIcon,
  CreditsInact,
  Failure,
  Img,
  RetryIcon,
} from "../../assets/images";
import { generateAd } from "../../Pages/ApiService/ApiService";
import { useNavigate } from "react-router";

const AddComponent = ({ creditUpdate }) => {
  // const initialImages = [
  //   "PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNzQiIGhlaWdodD0iMTc0IiB2aWV3Qm94PSIwIDAgMTc0IDE3NCI+DQogIDxnIGlkPSJpbWciIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MzUgLTQzMikiPg0KICAgIDxyZWN0IGlkPSJwZXhlbHMta3NlbmlhLWNoZXJuYXlhLTM5NTIwNzYiIHdpZHRoPSIxNzQiIGhlaWdodD0iMTc0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3MzUgNDMyKSIgZmlsbD0iI2U5ZjBmOCIvPg0KICAgIDxnIGlkPSJJY29uX2ZlYXRoZXItaW1hZ2UiIGRhdGEtbmFtZT0iSWNvbiBmZWF0aGVyLWltYWdlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4MDQuNSA1MDEuNSkiPg0KICAgICAgPHBhdGggaWQ9IlBhdGhfMTY1NSIgZGF0YS1uYW1lPSJQYXRoIDE2NTUiIGQ9Ik03LjUsNC41aDIxYTMsMywwLDAsMSwzLDN2MjFhMywzLDAsMCwxLTMsM0g3LjVhMywzLDAsMCwxLTMtM1Y3LjVhMywzLDAsMCwxLDMtM1oiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2EwYWVjYiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiLz4NCiAgICAgIDxwYXRoIGlkPSJQYXRoXzE2NTYiIGRhdGEtbmFtZT0iUGF0aCAxNjU2IiBkPSJNMTUsMTIuNzVhMi4yNSwyLjI1LDAsMSwxLTIuMjUtMi4yNUEyLjI1LDIuMjUsMCwwLDEsMTUsMTIuNzVaIiBmaWxsPSJub25lIiBzdHJva2U9IiNhMGFlY2IiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIzIi8+DQogICAgICA8cGF0aCBpZD0iUGF0aF8xNjU3IiBkYXRhLW5hbWU9IlBhdGggMTY1NyIgZD0iTTMxLjUsMjIuNSwyNCwxNSw3LjUsMzEuNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjYTBhZWNiIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMyIvPg0KICAgIDwvZz4NCiAgPC9nPg0KPC9zdmc+DQo=",
  //   "PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNzQiIGhlaWdodD0iMTc0IiB2aWV3Qm94PSIwIDAgMTc0IDE3NCI+DQogIDxnIGlkPSJpbWciIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MzUgLTQzMikiPg0KICAgIDxyZWN0IGlkPSJwZXhlbHMta3NlbmlhLWNoZXJuYXlhLTM5NTIwNzYiIHdpZHRoPSIxNzQiIGhlaWdodD0iMTc0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3MzUgNDMyKSIgZmlsbD0iI2U5ZjBmOCIvPg0KICAgIDxnIGlkPSJJY29uX2ZlYXRoZXItaW1hZ2UiIGRhdGEtbmFtZT0iSWNvbiBmZWF0aGVyLWltYWdlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4MDQuNSA1MDEuNSkiPg0KICAgICAgPHBhdGggaWQ9IlBhdGhfMTY1NSIgZGF0YS1uYW1lPSJQYXRoIDE2NTUiIGQ9Ik03LjUsNC41aDIxYTMsMywwLDAsMSwzLDN2MjFhMywzLDAsMCwxLTMsM0g3LjVhMywzLDAsMCwxLTMtM1Y3LjVhMywzLDAsMCwxLDMtM1oiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2EwYWVjYiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiLz4NCiAgICAgIDxwYXRoIGlkPSJQYXRoXzE2NTYiIGRhdGEtbmFtZT0iUGF0aCAxNjU2IiBkPSJNMTUsMTIuNzVhMi4yNSwyLjI1LDAsMSwxLTIuMjUtMi4yNUEyLjI1LDIuMjUsMCwwLDEsMTUsMTIuNzVaIiBmaWxsPSJub25lIiBzdHJva2U9IiNhMGFlY2IiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIzIi8+DQogICAgICA8cGF0aCBpZD0iUGF0aF8xNjU3IiBkYXRhLW5hbWU9IlBhdGggMTY1NyIgZD0iTTMxLjUsMjIuNSwyNCwxNSw3LjUsMzEuNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjYTBhZWNiIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMyIvPg0KICAgIDwvZz4NCiAgPC9nPg0KPC9zdmc+DQo=",
  //   "PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNzQiIGhlaWdodD0iMTc0IiB2aWV3Qm94PSIwIDAgMTc0IDE3NCI+DQogIDxnIGlkPSJpbWciIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MzUgLTQzMikiPg0KICAgIDxyZWN0IGlkPSJwZXhlbHMta3NlbmlhLWNoZXJuYXlhLTM5NTIwNzYiIHdpZHRoPSIxNzQiIGhlaWdodD0iMTc0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3MzUgNDMyKSIgZmlsbD0iI2U5ZjBmOCIvPg0KICAgIDxnIGlkPSJJY29uX2ZlYXRoZXItaW1hZ2UiIGRhdGEtbmFtZT0iSWNvbiBmZWF0aGVyLWltYWdlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4MDQuNSA1MDEuNSkiPg0KICAgICAgPHBhdGggaWQ9IlBhdGhfMTY1NSIgZGF0YS1uYW1lPSJQYXRoIDE2NTUiIGQ9Ik03LjUsNC41aDIxYTMsMywwLDAsMSwzLDN2MjFhMywzLDAsMCwxLTMsM0g3LjVhMywzLDAsMCwxLTMtM1Y3LjVhMywzLDAsMCwxLDMtM1oiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2EwYWVjYiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiLz4NCiAgICAgIDxwYXRoIGlkPSJQYXRoXzE2NTYiIGRhdGEtbmFtZT0iUGF0aCAxNjU2IiBkPSJNMTUsMTIuNzVhMi4yNSwyLjI1LDAsMSwxLTIuMjUtMi4yNUEyLjI1LDIuMjUsMCwwLDEsMTUsMTIuNzVaIiBmaWxsPSJub25lIiBzdHJva2U9IiNhMGFlY2IiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIzIi8+DQogICAgICA8cGF0aCBpZD0iUGF0aF8xNjU3IiBkYXRhLW5hbWU9IlBhdGggMTY1NyIgZD0iTTMxLjUsMjIuNSwyNCwxNSw3LjUsMzEuNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjYTBhZWNiIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMyIvPg0KICAgIDwvZz4NCiAgPC9nPg0KPC9zdmc+DQo=",
  // ];
  const [isLoading, setLoading] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [active, setActive] = useState("1");
  const [inputData, setInputData] = useState("");
  const [description, setdescription] = useState("");
  const [imageType, setImageType] = useState("Cartoon");
  const [data, setdata] = useState("");
  const [parentId, setparentId] = useState("");
  const [req_id, setreq_id] = useState(0);
  const [has_image, sethas_image] = useState(false);
  const [imgss, setimgss] = useState();
  const [adCount, setAdCount] = useState(0);
  const [descCount, setDescCount] = useState(0);
  const [formValid, setFormValid] = useState(true);
  const [generateThinking,setGenerateThinking] = useState(false) 

  let navigate = useNavigate();

  const responseRef = useRef();

  const textChange = (e) => {
    setInputData(e.target.value);
    const adcount = e.target.value.length;
    setAdCount(adcount);
  };
  const imageDescChange = (e) => {
    setdescription(e.target.value);
    const desccount = e.target.value.length;
    setDescCount(desccount);
  };

  const retrycall = () => {
    creditUpdate();
  };
  const validateForm = () => {
    let isValid = true;
    if (adCount === 0 || descCount === 0) {
      isValid = false;
    }

    return isValid;
  };

  const generateApiCall = () => {
    setFormValid(validateForm());
    if (validateForm()) {
      setAddShow(false);
      setLoading(true);
      setGenerateThinking(true)
      generateAd({
        data: inputData,
        image: description,
        style: imageType,
        // parent_id: "70",
      })
        .then((res) => {
          console.log(res);
          creditUpdate();
          console.log(res.data.Image);
          setdata(res.data.Data);
          setimgss(res.data.Image);
          setreq_id(res.data.Request_id);
          setparentId(res.data.Parent_id);
          sethas_image(res.data.has_image);
          setAddShow(true);
          setLoading(false);
          if (
            res.data.status ===
            "You have insufficient credits for the operation"
          ) {
            setAddShow(false);
            toast.error(res.data.status, {
              hideProgressBar: true,
              closeButton: false,
              style: {background: "#FAD6D6", border:"1px solid #C75858", minHeight:"1px"},
            icon:<img alt="info" src={Failure} />
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.openai_error?.message, {
            hideProgressBar: true,
            closeButton: false,
            style: {background: "#FAD6D6", border:"1px solid #C75858", minHeight:"1px"},
            icon:<img alt="info" src={Failure} />
          });
          setLoading(false);
          toast.error(err.response.data.status, {
            hideProgressBar: true,
            closeButton: false,
            style: {background: "#FAD6D6", border:"1px solid #C75858", minHeight:"1px"},
            icon:<img alt="info" src={Failure} />
          });
        });
    }
  };
const tooManyRequestHandler=(data)=>{
  console.log(data,"testsethu")
  sethas_image(data)
}
  const AfterGenerate = () => {
    responseRef.current.textRetry();
  };
  const AfterGenerateImage = () => {
    responseRef.current.imageRetry();
  };
  const handleClick = (event) => {
    setActive(event.target.id);
    setImageType(event.target.name);
  };

  const AdDummy = () => {
    return (
      <div className="col-md-12 col-xl-7 tabscreen">
        <div className="float-xl-end marginTop mb-5">
          <div className="sideWidth">
            <h1 className="heading p-0 m-0">Ad results will appear here</h1>
            <div className="d-flex justify-content-around mt-4">
              <div className="col">
                <h6 className="m-0 mb-2 subTitle">Content</h6>
                
                {
                  generateThinking ?(
                    <div className="container textLoader mt-2">
                    <div className="loaderWdth">
                      <Lottie animationData={ImageLoader} />
                      <p className="think mt-2">Thinking...</p>
                    </div>
                  </div>
                  ):
                  <div className="col-md-12 mt-2">
                  <div className="emptyBackground"></div>
                </div>
                }
              </div>
            </div>
            <div className="mt-2"></div>
            <div className="d-flex mt-4">
              <div className="col">
                <h6 className="m-0 subTitle">Image Results</h6>
              </div>
            </div>
          
            <div className="d-flex flex-wrap justify-content-around mt-3">
          
          <div className="me-6 mt-3 mt-md-0 ">
                  {generateThinking ?(
                  <div className="imgThinking">
                  <div className="loaderWdth">
                  <Lottie animationData={ImageLoader} />
                  <p className="think mt-2">Thinking...</p>
                  </div>
                  </div>
              
                ):
                <div className="me-6 mt-3 mt-md-0">
                <img src={Img} alt="Image1" className="imgWidth" />
                </div>
                }
           </div>

           <div className="me-6 mt-3 mt-md-0 ">
               {generateThinking ?(
                <div className="imgThinking">
                <div className="loaderWdth">
                <Lottie animationData={ImageLoader} />
                <p className="think mt-2">Thinking...</p>
              </div>
              </div>
                ):
                <div className="me-6 mt-3 mt-md-0 ">
                <img src={Img} alt="Image1" className="imgWidth" />
                </div>
                } 
            </div>

            <div className="me-6 mt-3 mt-md-0 ">
                {generateThinking ?(
                  <div className="imgThinking">
                <div className="loaderWdth">
                <Lottie animationData={ImageLoader} />
                <p className="think mt-2">Thinking...</p>
                </div>
              </div>
                ):
                <div className="me-6 mt-3 mt-md-0 ">
                <img src={Img} alt="Image1" className="imgWidth" />
                </div>
                }
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="col-md-12 col-xl-5 col-lg-12 col-sm-12 col-xxl-5 borderend ">
      {/* <div className="col-12 borderend tabscreen"> */}
        <div className="width marginTop">
          <h1 className="heading m-0">Create New Ad</h1>
          <form>
            <div className="labelForm">
              <h1 className="heading m-0">Content</h1>
              <label className="mt-2">Write an ad for</label>
              <span className="float-end number">{adCount}/150</span>
            </div>
            <input
              placeholder="'Simply Superb' book store"
              className={
                "form-control w-100 formInput " +
                (!formValid && adCount === 0 ? "border-warning" : "")
              }
              value={inputData}
              required
              maxLength={150}
              onChange={textChange}
            />
            {!formValid && adCount === 0 ? (
              <span className="text-danger">Required</span>
            ) : null}

            {addShow ? (
              <div className="mt-3">
                <button
                  className="searchBtn cursorPointer"
                  onClick={AfterGenerate} 
                  disabled={!has_image}
                >
                  <img src={RetryIcon} alt="" />{" "}
                  <span className="retryFont">Retry with different prompt</span>
                </button>
                <span className="mt-2 ms-2">
                  <img src={CreditsInact} alt="credits" />
                  <span className="labelForm label ms-2">
                    Requires 1 Credit
                  </span>
                </span>
              </div>
            ) : null}
            <hr></hr>
            <div className="labelForm">
              <h1 className="heading m-0">Image</h1>
              <label className="mt-2">Image Description</label>
              <span className="float-end number">{descCount}/150</span>
            </div>
            <input
              placeholder="interior of a book store with lots of books in shelves"
              className={
                "form-control w-100 formInput " +
                (!formValid && descCount === 0 ? "border-warning" : "")
              }
              value={description}
              required
              maxLength={150}
              onChange={imageDescChange}
            />
            {!formValid && descCount === 0 ? (
              <span className="text-danger">Required</span>
            ) : null}
          </form>
          <div className="d-flex flex-wrap mt-3">
            <button
              key={1}
              className={
                active === "1" ? "activeBtn photoBtn me-2" : "photoBtn me-2"
              }
              id={"1"}
              disabled={isLoading}
              onClick={handleClick}
              name="Cartoon"
            >
              <img
                alt="checkIcon"
                src={active === "1" ? CheckBlueIcon : CheckGreyIcon}
                className="me-1"
                onClick={handleClick}
              />
              Cartoon
            </button>
            <button
              key={2}
              className={
                active === "2" ? "activeBtn photoBtn me-2" : "photoBtn me-2"
              }
              id={"2"}
              disabled={isLoading}
              onClick={handleClick}
              name="Watercolor"
            >
              <img
                alt="checkIcon"
                src={active === "2" ? CheckBlueIcon : CheckGreyIcon}
                className="me-1"
                onClick={handleClick}
              />
              Watercolor
            </button>
            <button
              key={3}
              className={
                active === "3" ? "activeBtn photoBtn me-2" : "photoBtn me-2"
              }
              id={"3"}
              disabled={isLoading}
              onClick={handleClick}
              name="Photo"
            >
              <img
                alt="checkIcon"
                onClick={handleClick}
                src={active === "3" ? CheckBlueIcon : CheckGreyIcon}
                className="me-1"
              />
              Photo
            </button>
          </div>
          {addShow ? (
            <div className="mt-3">
              <button
                className="searchBtn cursorPointer"
                onClick={AfterGenerateImage}
                disabled={!has_image}
              >
                <img src={RetryIcon} alt="" />{" "}
              <span className="retryFont">Retry with different prompt</span>
              </button>
              <span className="mt-2 ms-2">
                <img src={CreditsInact} alt="credits" />
                <span className="labelForm label ms-2">Requires 1 Credit</span>
              </span>
            </div>
           ) : null}
          {!data?
          <button
          className={isLoading ? "genDisable" : "generateBtn"}
          onClick={generateApiCall}
          disabled={isLoading}
        >
          Generate
        </button>:
        !has_image?
        <button
      className="genDisable"
      disabled
    >
      <img src={Loadingspin} alt="loading" className="loading"/>
    </button>
        :
      <button
      className="generateBtn"
      onClick={generateApiCall}
      disabled={!has_image}
    >
      Generate
    </button>}
          <div className="mt-2">
            <img src={CreditsInact} alt="credits" />
            <span className="labelForm label ms-2">Requires 2 Credits</span>
          </div>
        </div>
      </div>
      {isLoading ? (
        // <div className="container loaderTransform">
        //   <div className="loaderWidth">
        //     <Lottie animationData={LoadingSpinner} />
        //     <p className="bigThink mt-3">Thinking...</p>
        //   </div>
        // </div>
        <AdDummy />
      ) : addShow ? (
        <AdResponse
          ref={responseRef}
          imageType={imageType}
          data={data}
          addShow={addShow}
          isLoading={isLoading}
          imgss={imgss}
          req_id={req_id}
          has_image={has_image}
          description={description}
          inputData={inputData}
          parentId={parentId}
          retrycall={retrycall}
          tooManyRequestHandler={tooManyRequestHandler}
        />
      ) : (
        <AdDummy />
      )}
    </>
  );
};

export default AddComponent;
